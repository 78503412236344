import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BucketListItem from './BucketListItem';
import { filterBucketListItems } from '../../selectors/bucketListItems';
import BucketListFilters from './BucketListFilters';
import { bucketListMeta } from '../../data/meta';
import { cfirst } from '../../utils/helpers';
import { sortByBest } from '../../actions/bucketListFilters';
import BucketListInfo from './BucketListInfo';
import PageReady from '../global/PageReady';
import { sendGAEvent } from '../../utils/ga';
import GoogleAd from '../ads/GoogleAd';

class BucketList extends React.PureComponent {
	constructor(props) {
		super(props);
		const { items, location, dispatch } = this.props;
		const active = location.hash.split('#')[1];

		const resetSorting = this.props.history.action === 'PUSH';
		if (resetSorting) dispatch(sortByBest());

		this.state = {
			active,
			items,
			sortBy: resetSorting ? 'best' : this.props.sortBy,
			updateList: true,
			renderAllItems: this.props.history.action !== 'PUSH',
		};
		this.setUrlHash('');
	};

	static getDerivedStateFromProps(props, prevState) {
		const populateItemsWithNewData = (arr, item) => {
			const stateItem = props.items.find(i => i.id === item.id);
			if (stateItem) arr.push({ ...stateItem });
			return arr;
		};
		const sortByChanged = props.sortBy !== prevState.sortBy;
		const noItems = prevState.items.length === 0;
		return {
			active: props.sortBy === prevState.sortBy ? prevState.active : undefined,
			items: sortByChanged || noItems || prevState.updateList ? filterBucketListItems(props.items, props.sortBy) : prevState.items.reduce(populateItemsWithNewData, []),
			sortBy: props.sortBy,
			updateList: false
		};
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState(() => ({ renderAllItems: true }));
		}, 0);
	};

	setActive = (id, title) => {
		this.setState(() => ({ active: id }));
		if (id) sendGAEvent('bucketListItemExpand', { title });
	};

	setUrlHash = (id) => {
		this.props.history.replace({ hash: id });
	};

	render() {
		const { items, sortBy } = this.state;
		const { lang, city } = this.props;
		const itemsToRender = this.state.renderAllItems ? items : items.slice(0, 12);

		return (
			<Fragment>
				<Helmet>
					<title>{bucketListMeta.title[lang]} - Opaste {cfirst(city)}</title>
					<meta name="description" content={bucketListMeta.desc[lang]} />
				</Helmet>

				<BucketListFilters/>

				<BucketListInfo/>

				<div>
					{itemsToRender.map((item, i) => (
						<BucketListItem
							key={item.id}
							number={sortBy === 'best' && i + 1}
							isActive={this.state.active === item.id}
							setActive={this.setActive}
							setUrlHash={this.setUrlHash}
							{...item}
						/>
						))}
					{ itemsToRender.length > 0 && <PageReady />}
				</div>

				{itemsToRender.length > 0 && <GoogleAd style={{ marginTop: '2rem' }} slot="bucketList" />}
			</Fragment>
		);
	};
};

const mapStateToProps = (state) => ({
	items: state.bucketListItems,
	sortBy: state.bucketListFilters.sortBy,
	lang: state.app.currentLanguage,
	city: state.app.currentLocation.city
});

export default withRouter(connect(mapStateToProps)(BucketList));