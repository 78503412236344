export default (state = [], action) => {
	switch (action.type) {
		case 'SET_SIGHTS':
			return [
				...action.sights
			];
		case 'UPDATE_SIGHTS':
			const ids = action.sights.map(sight => sight.id);
			return state.map(sight => {
				return ids.indexOf(sight.id) > -1
					? action.sights.find(s => s.id === sight.id)
					: { ...sight };
			});
		default:
			return state;
	}
};