import React from 'react';
import { connect } from 'react-redux';
import Loader from '../global/Loader';
import BucketList from './BucketList';

class BucketListDashboard extends React.Component {
	render() {
		const { items } = this.props;
		return (
			<div className="container" style={{ maxWidth: 530 }}>
				{ items.length ? (
					<BucketList items={items} />
				) : (
					<Loader />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	items: state.bucketListItems
});

export default connect(mapStateToProps)(BucketListDashboard);