import { firestore } from './firestore';
import { setSights } from '../actions/sights';
import { setDetails } from '../actions/sightDetails';
import { getDistanceBetweenPoints } from '../utils/helpers';

// Sight data

export const listenForSightsChanges = (city, store) => {
	const sightsRef = firestore.collection(`cities/${city}/sights`)
		.where('likes', '>', -10);

	sightsRef.onSnapshot((querySnapshot) => {
		const sights = querySnapshot.docs
			.filter(doc => doc.exists)
			.map(doc => ({
				id: doc.id,
				...doc.data(),
				distanceFromUser: getDistanceBetweenPoints(doc.data().coordinates, store.getState().user.coords)
			}));

		store.dispatch(setSights(sights));
	}, err => console.warn('Error getting sight data snapshot:', err));
};

// Sights details

export const fetchSightDetails = (id, detailsRef, dispatch) => {
	return detailsRef.onSnapshot((doc) => {
		if (!doc.exists) return;
		dispatch(setDetails({ id, details: doc.data() }));
	}, err => console.warn('Error calling get on detailsRef:', err));
};