import React from 'react';
import { closestToCentre, closestToMe, title, mostPopular, sortByText, searchSights } from '../../data/translations';

const sortByTranslations = {
	title,
	distance: closestToCentre,
	userDistance: closestToMe,
	best: mostPopular
};

class SightMapFilters extends React.PureComponent {
	onSortByChange = (e) => {
		const filters = {
			...this.props.filters,
			sortBy: e.target.value
		};
		this.props.onFiltersChanged(filters);
	}

	onTextChange = (e) => {
		const filters = {
			...this.props.filters,
			text: e.target.value
		};
		this.props.onFiltersChanged(filters);
	}

	onCategoryChange = (e) => {
		const oldTags = this.props.filters.tags;
		const changedTags = e.target.value.split(',');

		const tags = e.target.checked
			? [...oldTags, ...changedTags]
			: oldTags.filter(tag => !changedTags.includes(tag));

		const filters = { ...this.props.filters, tags };
		console.log({ oldTags, changedTags, tags });
		this.props.onFiltersChanged(filters);
	}

	render() {
		const { lang, filters, randomSight, categories } = this.props;
		return (
			<div className="map-filters">
				<div className="map-filters__group">
					<label htmlFor="filter-text" className="map-filters__label">{searchSights[lang]}</label>
					<input
						if="filter-text"
						className="map-filters__input"
						onInput={this.onTextChange}
						value={filters.text}
						placeholder={randomSight}
					/>
				</div>

				<div className="map-filters__group">
					<label htmlFor="filter-sort-by" className="map-filters__label">{sortByText[lang]}</label>
					<div className="map-filters__select-wrapper">
						<select
							id="filter-sort-by"
							className="map-filters__select"
							value={filters.sortBy}
							onChange={this.onSortByChange}
						>
							<option value="title">{title[lang]}</option>
							<option value="best">{mostPopular[lang]}</option>
							{navigator.geolocation && <option value="userDistance">{closestToMe[lang]}</option>}
							<option value="distance">{closestToCentre[lang]}</option>
						</select>
						<div className="map-filters__select-value">
							<div>{sortByTranslations[filters.sortBy][lang]}</div>
							<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M24 3l-12 18-12-18z"/></svg>
						</div>
					</div>
				</div>

				{categories.map(category => (
					<div key={category.pageName}>
						<label>
							<input
								type="checkbox"
								name={category.pageName}
								value={category.tags.join(',')}
								onChange={this.onCategoryChange}
							/>
							<span>{category.title}</span>
						</label>
						{category.hasSubCategories && category.subCategories && (
							<div>
								{category.subCategories.map(subcategory => (
									<label key={subcategory.pageName}>
									<input
										type="checkbox"
										name={subcategory.pageName}
										value={subcategory.tags.join(',')}
										onChange={this.onCategoryChange}
									/>
									<span>{subcategory.title}</span>
								</label>
								))}
							</div>
						)}
					</div>
				))}
			</div>
		);
	}
}

export default SightMapFilters;
