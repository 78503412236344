import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BackButton from '../global/BackButton';
import pages from '../../data/pages';

class HeaderTopBar extends React.Component {
	render() {
		const { appLocation, lang, pathname, history } = this.props;
		return (
			<div className="container">
				<div className="header-topbar">
					<BackButton
						classes="header-topbar__side-item"
						hidden={[pages.sights.link[lang], pages.bucketList.link[lang], '/'].includes(pathname)}
					/>
					<div className="header-location-selector">
						{/* <svg className="header-location-selector__pin-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg> */}
						<span className="header-location-selector__text">{appLocation}</span>
					</div>
					<div className="header-topbar__side-item">
					</div>
					{/* <Link to={'/'} className="header-topbar__side-item">
						<svg className="header-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z"/></svg>
					</Link> */}
				</div>
			</div>
		);
	};
};

const mapStateToProps = (state, router) => ({
	appLocation: state.app.currentLocation.city,
	lang: state.app.currentLanguage,
	pathname: router.location.pathname
});

export default withRouter(connect(mapStateToProps)(HeaderTopBar));