import React, { Fragment } from 'react';
import { cannotLoadOfflineMessage } from '../../data/translations';

const Loader = ({ text, showOfflineMessage, lang }) => (
	<Fragment>
		{(text || showOfflineMessage) && (
			<h4 className={'loader-text' + (showOfflineMessage ? ' loader-text--offline' : '')}>{text || cannotLoadOfflineMessage[lang]}</h4>
		)}
		{ !showOfflineMessage && (
			<div className={`loader ${text ? 'has-text' : ''}`}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		)}
	</Fragment>
);

export default Loader;