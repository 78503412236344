import firebase from 'firebase/app';
import 'firebase/firestore';

// Config
firebase.initializeApp({
    apiKey: "AIzaSyCZqxW7fmbWPBSi4hyTZZSjobrR2Dd9QAc",
    authDomain: "projectx-e0e02.firebaseapp.com",
    databaseURL: "https://projectx-e0e02.firebaseio.com",
    projectId: "projectx-e0e02",
    storageBucket: "projectx-e0e02.appspot.com",
    messagingSenderId: "682295850080"
});

// Firestore
const firestore = firebase.firestore();

const handlePersistenceError = (err) => {
	console.warn(`Error enabling firestore persistence: ${err.code}`)
	const databaseCleared = window.location.search.indexOf('?e=1') > -1;

	// Err code 0 could happen on InderxedDB version error
	if (err.code == '0' && !databaseCleared) {
		const req = indexedDB.deleteDatabase('firestore/[DEFAULT]/projectx-e0e02/main');
		req.onsuccess = function () {
			console.log("Deleted database successfully");
			window.location.href = `${window.location.href}?e=1`;
		};
		req.onerror = function () {
			console.log("Couldn't delete database");
		};
		req.onblocked = function () {
			console.log("Couldn't delete database due to the operation being blocked");
		};
	}
};

firestore.enablePersistence({ experimentalTabSynchronization: true })
  .catch(handlePersistenceError);

export { firestore };