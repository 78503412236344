import React from 'react';
import SightsListItem from './SightsListItem';
import { sightsVisible } from '../../data/translations';

class SightsList extends React.PureComponent {
	render() {
		const { sights, allSightsCount, lang, activeSight } = this.props;
		return (
			<div className="sights-list">
				<div className="sights-list__amount">
					<p>{sightsVisible[lang][0]} {sights.length} / {allSightsCount} {sightsVisible[lang][1]}</p>
					{sights.map(sight => (
						<SightsListItem
							key={sight.id}
							{...sight}
							isActive={sight.id === activeSight}
						/>
					))}
				</div>
			</div>
		);
	}
}

export default SightsList;