import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import BucketListDashboard from '../components/bucket-list/BucketListDashboard';
import NotFoundPage from '../components/global/NotFoundPage';
import Header from '../components/header/Header';
import NavTabs from '../components/header/NavTabs';
import SightItemPage from '../components/sights/SightItemPage';
import SightsCategoryRedirect from '../components/sights/SightsCategoryRedirect';
import Footer from '../components/global/Footer';
import PrivacyPolicy from '../components/content-pages/PrivacyPolicy';
import CookiePolicy from '../components/content-pages/CookiePolicy';
import About from '../components/content-pages/About';
import withTracker from './withTracker';
import pages from '../data/pages';
import SightMap from '../components/map/SightMap';


class AppRouter extends React.Component {
	scrollToTop = () => {
		window.scrollTo(0, 0);
		return null;
	};

	render() {
		const { lang } = this.props;
		return (
			<BrowserRouter>
				<LastLocationProvider>
					<Route component={this.scrollToTop} />
					<Header />
					<NavTabs />
					<main className="site-main">
						<Switch>
							<Redirect from='/' to={pages.sights.link[lang]} exact />
							<Route path={`${pages.allSights.link[lang]}/:id`} component={withTracker(SightItemPage)} exact />
							<Route path={pages.sights.link[lang]} component={withTracker(SightsCategoryRedirect)} exact />
							<Route path={`${pages.sights.link[lang]}/:categoryName`} component={withTracker(SightsCategoryRedirect)} />

							<Route path={pages.bucketList.link[lang]} component={withTracker(BucketListDashboard)} />
							<Route path={pages.privacyPolicy.link[lang]} component={withTracker(PrivacyPolicy)} />
							<Route path={pages.cookiePolicy.link[lang]} component={withTracker(CookiePolicy)} />
							<Route path={pages.about.link[lang]} component={withTracker(About)} />

							<Route path={pages.map.link[lang]} component={withTracker(SightMap)} />
							<Route component={NotFoundPage} />
						</Switch>
					</main>
					<Footer />
				</LastLocationProvider>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(AppRouter);