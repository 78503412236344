import configureStore from '../store/configureStore';
import { listenForSightsChanges } from '../firestore/sights';
import { fetchSightCategories } from '../firestore/sightCategories';
import { listenForBucketListChanges } from '../firestore/bucketList';
import pages from '../data/pages';

const fetchSightDataFromFirebase = (location, store) => {
	// Fetch sight categories from firestore
	fetchSightCategories(location, store);
	// Fetch sights data from firestore
	listenForSightsChanges(location, store);
}

const fetchBucketListDataFromFirebase = (location, store) => {
	// Fetch bucket list items from firestore
	listenForBucketListChanges(location, store);
};

export default () => {
	const store = configureStore();
	const { currentLocation: location, currentLanguage: lang } = store.getState().app;

	if (window.location.pathname.startsWith(pages.sights.link[lang])) {
		fetchSightDataFromFirebase(location.city, store);
		fetchBucketListDataFromFirebase(location.city, store);
	} else {
		fetchBucketListDataFromFirebase(location.city, store);
		fetchSightDataFromFirebase(location.city, store);
	}

	return store;
}