import { firestore } from './firestore';
import { setBucketListItems } from '../actions/bucketListItems';

export const listenForBucketListChanges = (city, store) => {
	const bucketListRef = firestore.collection(`cities/${city}/bucketList`)
		.where('visible', '==', true);

	bucketListRef.onSnapshot((querySnapshot) => {
		const items = querySnapshot.docs
			.filter(doc => doc.exists)
			.map(doc => ({
				id: doc.id,
				...doc.data()
			}));
		store.dispatch(setBucketListItems(items));
	}, (error) => {
		console.warn('Error getting bucket list data snapshot:', error);
	});
};
