import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SightsListItem from './SightsListItem';
import { filterSights } from '../../selectors/sights';
import SightsListFilters from './SightsListFilters';
import Loader from '../global/Loader';
import PageReady from '../global/PageReady';
import { findingYourLocation } from '../../data/translations';
import { resetReverseSort } from '../../actions/sightFilters';
import GoogleAd from '../ads/GoogleAd';

class SightsList extends React.PureComponent {
	constructor(props) {
		super(props);
		const { sights, sortBy, lang, userCoords, category, dispatch } = this.props;

		const resetReverseSorting = this.props.history.action === 'PUSH';
		if (resetReverseSorting) dispatch(resetReverseSort());

		this.state = {
			sights,
			sortBy,
			reversedSort: resetReverseSorting ? false : this.props.reversedSort,
			tags: category.tags,
			lang,
			userCoords,
			updateList: true,
			renderAllSights: this.props.history.action !== 'PUSH'
		};
	};

	static getDerivedStateFromProps(props, prevState) {
		const populateSightsWithNewData = (arr, sight) => {
			const stateSight = props.sights.find(i => i.id === sight.id);
			if (stateSight) arr.push({ ...stateSight });
			return arr;
		};
		const sortingChanged = props.sortBy !== prevState.sortBy || props.reversedSort !== prevState.reversedSort;
		const noSights = prevState.sights.length === 0;
		return {
			sights: sortingChanged || noSights || prevState.updateList ? filterSights({ sights: props.sights, sortBy: props.sortBy, reversedSort: props.reversedSort, tags: prevState.tags, sightDetails: props.sightDetails }) : prevState.sights.reduce(populateSightsWithNewData, []),
			sortBy: props.sortBy,
			lang: props.lang,
			userCoords: props.userCoords,
			reversedSort: props.reversedSort,
			tags: prevState.tags,
			updateList: false,
		};
	};

	componentDidMount() {
		setTimeout(() => {
			this.setState(() => ({ renderAllSights: true }));
		}, 0);
	};

	updateList = () => {
		this.setState(() => ({ updateList: true }));
	};

	render() {
		const { sortBy, reversedSort, userCoords, lang, sights } = this.state;
		const sightsToRender = this.state.renderAllSights ? sights : sights.slice(0, 27);

		return (
			<div className="container">
				<SightsListFilters
					categoryName={this.props.category.title}
					reversedSort={reversedSort}
					updateList={this.updateList}
				/>
				{sortBy === 'userDistance' && !userCoords ? (
					<Loader text={findingYourLocation[lang]} />
				) : (
					<div className="list">
						{sightsToRender.map(sight => (
							<SightsListItem
								key={sight.id}
								{...sight}
								sortBy={sortBy}
								lang={lang}
							/>
						))}
					</div>
				)}
				{sightsToRender.length > 0 && <PageReady />}

				{sightsToRender.length > 0 && <GoogleAd style={{ marginTop: '2rem' }} slot="sightsList" />}
			</div>
		);
	};
};

const mapStateToProps = (state) => ({
	sights: state.sights,
	sortBy: state.sightFilters.sortBy,
	reversedSort: state.sightFilters.reversedSort,
	sightDetails: state.sightDetails,
	lang: state.app.currentLanguage,
	userCoords: state.user.coords
});

export default withRouter(connect(mapStateToProps)(SightsList));