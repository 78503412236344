export default [
	{
		"elementType": "geometry",
			"stylers": [
			{
				"color": "#242f3e"
			}
		]
	},
	{
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#746855"
			}
		]
	},
	{
		"elementType": "labels.text.stroke",
			"stylers": [
			{
				"color": "#242f3e"
			}
		]
	},
	{
		"featureType": "administrative",
		"elementType": "geometry",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "administrative.locality",
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#d59563"
			}
		]
	},
	{
		"featureType": "poi",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#d59563"
			}
		]
	},
	{
		"featureType": "poi.attraction",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.business",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.government",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.medical",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.park",
			"stylers": [
			{
				"color": "#264d3f"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "geometry",
			"stylers": [
			{
				"color": "#263c3f"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "geometry.fill",
			"stylers": [
			{
				"color": "#263f3f"
			},
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#6b9a76"
			}
		]
	},
	{
		"featureType": "poi.place_of_worship",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.school",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.sports_complex",
		"elementType": "geometry",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry",
			"stylers": [
			{
				"color": "#38414e"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry.stroke",
			"stylers": [
			{
				"color": "#212a37"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels.icon",
			"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#9ca5b3"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry",
			"stylers": [
			{
				"color": "#746855"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
			"stylers": [
			{
				"color": "#1f2835"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#f3d19c"
			}
		]
	},
	{
		"featureType": "transit",
			"stylers": [
			{
			"visibility": "off"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "geometry",
			"stylers": [
			{
				"color": "#2f3948"
			}
		]
	},
	{
		"featureType": "transit.station",
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#d59563"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "geometry",
			"stylers": [
			{
				"color": "#17263c"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "geometry.fill",
			"stylers": [
			{
				"color": "#0f0c2a"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "labels.text.fill",
			"stylers": [
			{
				"color": "#515c6d"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "labels.text.stroke",
			"stylers": [
			{
				"color": "#17263c"
			}
		]
	}
];
