import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import Likes from '../global/Likes';
import { noAdditionalInformation, youCanAddLikesViaApplication } from '../../data/translations';
import pages from '../../data/pages';

class BucketListItem extends React.Component {
	constructor(props) {
		super(props);
	};

	onItemClick = (id, isActive, title) => {
		const activeId = isActive ? null : id;
		this.props.setActive(activeId, title);
	};

	onRelatedLinkClick = (id) => {
		this.props.setUrlHash(id);
	};

	render() {
		const { id, title, description, likes, number, isActive, relatedPlaces, sights, lang } = this.props;
		const allSightsUrl = pages.allSights.link[lang];
		return (
			<div className='bl-item'>
				<div className="bl-item__btn" onClick={() => { this.onItemClick(id, isActive, title) }} role="button" tabIndex="0">
					{ number && <p className="bl-item__number">{number}.</p> }
					<h2 className="bl-item__title">{ title }</h2>
					<Likes
						likes={likes}
						classes="bl-item__likes"
						tooltip={youCanAddLikesViaApplication[lang]}
						tooltipOnlyClickable
						tooltipTimeout={3000}
						lang={lang}
						gaEvent="bucketListLike"
						gaAction={{ title }}
					/>
				</div>

				<Collapse isOpened={isActive}>
					<div className="bl-item__content">
						{ (!description && !(relatedPlaces || []).length) && <i className="bl-item__description">{ noAdditionalInformation[lang] }</i> }
						{ description && <p className="bl-item__description">{description}</p> }
						{ (isActive && relatedPlaces && relatedPlaces.length > 0) && (
							<div className="bl-item__related-links">
								{ sights.slice(0).reduce((links, sight, i, arr) => {
									if (relatedPlaces.includes(sight.id)) {
										links.push(
											<Link
												className="bl-item__related-link"
												key={sight.id}
												to={`${allSightsUrl}/${sight.id}`}
												onClick={() => this.onRelatedLinkClick(id)}
											>
												{sight.title}
											</Link>
										)
									}
									if (links.length >= relatedPlaces.length) arr.splice(i);
									return links;
								}, []).sort((a,b) => relatedPlaces.indexOf(a.key) - relatedPlaces.indexOf(b.key))}
							</div>
						)}
					</div>
				</Collapse>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage,
	sights: state.sights
});

export default connect(mapStateToProps)(BucketListItem);