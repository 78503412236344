export const filterSights = ({ sights, text, sortBy, reversedSort, tags = [], sightDetails = [], includeOtherTags = false } = {}) => {
	let result = sights.filter((sight) => {
		let textMatch = true;
		if (text) {
			const titleMatch = sight.title.toLowerCase().includes(text.toLowerCase());
			// const details = sightDetails.find(details => details.id === sight.id);
			// const descriptionMatch = !!details && details.description.toLowerCase().includes(text.toLowerCase());
			textMatch = titleMatch;
		}
		let tagsMatch = true;
		if (tags.length) {
			const sightTags = includeOtherTags
				? [...sight.tags.main, ...sight.tags.other]
				: sight.tags ? sight.tags.main : [];
			tagsMatch = tags.filter(tag => tag.length && sightTags.includes(tag)).length;
		}
		return textMatch && tagsMatch;
	})
	.sort((a, b) => {
		if (sortBy === 'title') {
			return a.title < b.title ? -1 : 1;
		} else if (sortBy === 'distance') {
			return a.distanceFromCentre < b.distanceFromCentre ? -1 : 1;
		} else if (sortBy === 'best') {
			return a.likes !== b.likes
				? a.likes > b.likes ? -1 : 1
				: a.title < b.title ? -1 : 1;
		} else if (sortBy === 'userDistance') {
			return a.distanceFromUser < b.distanceFromUser ? -1 : 1;
		}
	});

	if (reversedSort) result = result.reverse().slice(0);
	return result;
};

export const getSightInfoById = (sights, sightDetails, id) => ({
	...sights.find(sight => sight.id === id),
	...sightDetails[id]
});