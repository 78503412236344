import React from 'react';
import { connect } from 'react-redux';
import Map from './Map';
import SightsList from './SightsList';
import SightMapFilters from './SightMapFilters';
import { filterSights } from '../../selectors/sights';

class SightMap extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeSight: null,
			filters: {
				sortBy: 'title',
				text: '',
				tags: []
			}
		};
		const filteredSights = filterSights({
			sights: this.props.sights,
			filters: this.state.filters
		});
		this.state.filteredSights = filteredSights;
		this.state.randomSight = this.getRandomSight(filteredSights);
	};

	getRandomSight = sights => sights.length ? sights[Math.floor(Math.random() * sights.length)].title : '';

	componentWillReceiveProps(nextProps){
		const equals = (prevSights, nextSights) => prevSights.length === nextSights.length && prevSights.every((o,i) => Object.keys(o).length === Object.keys(nextSights[i]).length && Object.keys(o).every(k => o[k] === nextSights[i][k]));

		if (!nextProps.sights.length || equals(this.props.sights, nextProps.sights)) return;
		this.setState((prevState) => {
			const filteredSights = filterSights({ sights: nextProps.sights, ...prevState.filters });
			return {
				filteredSights,
				randomSight: this.getRandomSight(filteredSights)
			};
		});
	};

	setActiveSight = (id) => {
		this.setState({ activeSight: id });
	};

	setFilters = (filters) => {
		console.log('set filters');
		this.setState((prevState) => {
			const filteredSights = filters.text.length > 1
				? filterSights({ sights: this.props.sights, ...filters })
				: filterSights({ sights: this.props.sights, ...filters, text: '' });
			return {
				filteredSights,
				filters,
				randomSight: filters.text !== prevState.filters.text
					? this.getRandomSight(filteredSights)
					: prevState.randomSight
			}
		});
	};

	render() {
		return (
			<div className="container">
				<div className="sight-map-list-wrapper">
					<SightMapFilters
						categories={this.props.categories}
						filters={this.state.filters}
						onFiltersChanged={this.setFilters}
						randomSight={this.state.randomSight}
						lang={this.props.lang}
					/>
					<Map
						center={this.props.cityCentre}
						sights={this.state.filteredSights}
						onMarkerClick={this.setActiveSight}
						activeSight={this.state.activeSight}
						lang={this.props.lang}
					/>
					<SightsList
						sights={this.state.filteredSights}
						allSightsCount={this.props.sights.length}
						activeSight={this.state.activeSight}
						lang={this.props.lang}
					/>
				</div>
			</div>
		);
	}
};


const mapStateToProps = state => ({
	sights: filterSights({ sights: state.sights, sortBy: 'title' }).filter(sight => !sight.image),
	categories: state.sightCategories,
	cityCentre: state.app.currentLocation.cityCentre,
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(SightMap);
