import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack } from '../../data/translations';
import { withLastLocation } from 'react-router-last-location';

const BackButton = ({ history, hidden, classes, lang, lastLocation }) => (
	<Fragment>
		{ lastLocation !== null ? (
			<button
				className={'btn-unstyled back-button ' + classes + (hidden ? ' back-button--hidden' : '')}
				onClick={() => { history.goBack(); }}
				disabled={hidden}
				aria-label={goBack[lang]}
			>
				<svg className="back-button__icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg>
			</button>
		) : (
			<div className={classes}></div>
		)}
	</Fragment>
);

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default withLastLocation(withRouter(connect(mapStateToProps)(BackButton)));