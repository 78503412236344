import React, { Fragment } from 'react';
import { facebookPage, website } from '../data/translations';

const roundToTen = num => Math.round(num / 10) * 10;


export const formatDistance = (distance) => {
	const useKilometers = distance >= 1000;
	const decimals = distance > 100000 ? 0 : 1;
	return useKilometers
		? `${(roundToTen(distance) / 1000).toFixed(decimals)}km`
		: `${roundToTen(distance)}m`;
};


export const addressJSX = (address, postal, city) => (
	<Fragment>
		<span style={{ display: 'inline-block'}}>{address || ''}{postal || city ? ',\u00A0' : '\u00A0'}</span>
		<span style={{ display: 'inline-block'}}>{postal ? postal + ' ' : ''}{city || ''}</span>
	</Fragment>
);


export const formatLink = (link, lang) => link.includes('facebook.com') ? facebookPage[lang] : website[lang];


export const descriptionToHTML = text => text.split('\\n\\n').map((text, i) => <p key={'p-'+i}>{text.split('\\n').map((line, j) => <Fragment key={'frag-'+j}><span>{line}</span><br /></Fragment>)}</p>);


export const googleMapsURL = ({ latitude, longitude}, googlePlaceId) => `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}${googlePlaceId ? `&query_place_id=${googlePlaceId}` : ''}`;


export const getDistanceBetweenPoints = (point1 = {}, point2 = {}) => {
	const deg2rad = deg => deg * (Math.PI/180);

	const { latitude: lat1, longitude: lon1 } = point1;
	const { latitude: lat2, longitude: lon2 } = point2;
	if (!lat1 || !lon1 || !lat2 || !lon2) return null;

	var R = 6371; // Radius of the earth in km
	var dLat = deg2rad(lat2-lat1);
	var dLon = deg2rad(lon2-lon1);
	var a =
		Math.sin(dLat/2) * Math.sin(dLat/2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
		Math.sin(dLon/2) * Math.sin(dLon/2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d = R * c; // Distance in km
	return Math.round(d*1000);
}

export const cfirst = str => str.charAt(0).toUpperCase() + str.slice(1);

export const formatMetaDescription = desc => desc.split('\\n')[0];

export const isAndroid = () => navigator.userAgent.toLowerCase().indexOf('android') > -1;
