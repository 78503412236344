import React from 'react';

const MapMarker = ({ id, title, $hover, onMarkerClick, isActive }) => (
	// TODO: Poista ternaryiden else-luokka
	<div className={'map-marker' + ($hover ? ' hover' : ' hover') + (isActive ? ' is-active' : ' is-active')}>
		<span className="map-marker__title">{title}</span>

		<button
			className="map-marker__btn"
			aria-label={title}
			onClick={onMarkerClick.bind(null, id)}
		>
			<svg className="map-marker__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
		</button>
	</div>
);

export default MapMarker;
