import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';
import mapStyles from './mapStyles';

class Map extends React.PureComponent {
	static defaultProps = {
		zoom: 12
	};

	createMapOptions = () => ({
		styles: mapStyles
	});

	render() {
		return (
			<div style={{ height: '800px', width: '100%' }}>
				<GoogleMapReact
					bootstrapURLKeys={{ key: '' }}
					defaultCenter={this.props.center}
					defaultZoom={this.props.zoom}
					language={this.props.lang}
					options={this.createMapOptions}
				>
					{this.props.sights.map(sight => (
						<MapMarker
							key={sight.id}
							{...sight}
							lat={sight.coordinates._lat}
							lng={sight.coordinates._long}
							onMarkerClick={this.props.onMarkerClick}
							isActive={sight.id === this.props.activeSight}
						/>
					))}
				</GoogleMapReact>
			</div>
		);
	}
}

export default Map;