import { createStore, combineReducers } from 'redux';
import appReducer from '../reducers/app';
import sightFiltersReducer from '../reducers/sightFilters';
import sightDetailsReducer from '../reducers/sightDetails';
import sightCategoriesReducer from '../reducers/sightCategories';
import sightsReducer from '../reducers/sights';
import userReducer from '../reducers/user';
import bucketListReducer from '../reducers/bucketList';
import bucketListFiltersReducer from '../reducers/bucketListFilters';

export default () => (
	createStore(
		combineReducers({
			app: appReducer,
			sights: sightsReducer,
			sightDetails: sightDetailsReducer,
			sightFilters: sightFiltersReducer,
			sightCategories: sightCategoriesReducer,
			bucketListItems: bucketListReducer,
			bucketListFilters: bucketListFiltersReducer,
			user: userReducer
		})
	)
);