import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import pages from '../../data/pages';

const NavTabs = ({ lang }) => (
	<div className="nav-tabs-wrapper">
		<div className="container--mobile-fullwidth">
			<nav className="nav-tabs">
				<NavLink
					to={pages.sights.link[lang]}
					className="nav-tabs__tab"
					activeClassName='is-active'
					>
					{pages.sights.title[lang]}
				</NavLink>
				<div className="nav-tabs__separator">
				</div>
				<NavLink
					to={pages.bucketList.link[lang]}
					className="nav-tabs__tab"
					activeClassName='is-active'
					>
					{pages.bucketList.title[lang]}
				</NavLink>
				<div className="nav-tabs__hightlighter">
				</div>
			</nav>
		</div>
	</div>
);

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default withRouter(connect(mapStateToProps)(NavTabs));