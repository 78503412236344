export const sightsMeta = {
	title: {
		fi: 'Elämykset',
		// en: 'Sights'
	},
	desc: {
		fi: 'Tekemistä ja nähtävää Joensuussa, löydä kaikki Joensuun elämykset helposti samasta paikasta.',
		// en: 'Things to see and do in Joensuu, find all sights in Joensuu from the same place.'
	}
}

export const allSightsMeta = {
	title: {
		fi: 'Kaikki Elämykset',
		// en: 'All Sights'
	},
	desc: {
		fi: 'Kaikki Joensuun elämykset listattuna yhdessä paikassa. Löydä tekemistä, nähtävyyksiä, ravintoloita, ulkoilumahdollisuuksia, vuotuisia tapahtumia, sekä paljon muuta vapaa-ajan tekemistä kaupungissa.',
		// en: 'All sights of Joensuu listed in one place. Find out what you want to see or do today in Joensuu.'
	}
}

export const sightMeta = {
	desc: {
		fi: 'Tietoa ja sijainti Joensuun kohteesta ',
		// en: 'Information and location of '
	}
}

export const bucketListMeta = {
	title: {
		fi: 'Bucket List',
		// en: 'Bucket List'
	},
	desc: {
		fi: 'Bucket List Joensuu on lista asioista, jotka jokaisen Joensuussa asuvan tai vierailevan tulisi tehdä ainakin kerran. Lista on käyttäjien ylläpitämä, ja voit itse vaikuttaa listaan tykkäämällä nykyisistä asioista tai ehdottamalla uusia.',
		// en: 'Joensuu Bucket List. Things which every person living or visiting Joensuu should see and do.'
	}
}

export const privacyPolicyMeta = {
	title: {
		fi: 'Tietosuojalauseke',
		// en: 'Privacy policy'
	},
	desc: {
		fi: 'Tietosuojalauseke',
		// en: 'Privacy policy'
	}
}

export const cookiePolicyMeta = {
	title: {
		fi: 'Evästekäytäntömme',
		// en: 'Cookie policy'
	},
	desc: {
		fi: 'Evästekäytäntö',
		// en: 'Cookie policy'
	}
}

export const aboutMeta = {
	title: {
		fi: 'Tietoa Opasteesta',
		// en: 'Cookie policy'
	},
	desc: {
		fi: 'Tietoa Opasteesta',
		// en: 'Cookie policy'
	}
}

export const sightsListMeta = {
	general: {
		desc: {
			fi: ' Joensuussa, kaikki Joensuusta yhdessä paikassa.',
			// en: ' in Joensuu, all of Joensuu in one place.'
		}
	},
	'nahtavyydet': {
		desc: {
			fi: 'Joensuun suosituimmat nähtävyydet. Tutustu kaikkiin Joensuun alueen nähtävyyksiin, kuten Pielisjokeen, Botaniaan, Taitokortteliin ja Susipatsaaseen.',
			// en: ''
		}
	},
	'tekemista': {
		desc: {
			fi: 'Tekemistä Joensuussa. Löydä hauskaa tekemistä ja upeita elämyksiä Joensuussa tutustumalla kaupungin suosituimpiin kohteisiin.',
			// en: ''
		}
	},
	'ruokailu': {
		desc: {
			fi: 'Ravintolat ja lounaspaikat Joensuussa. Löydä Joensuun parhaat ravintolat ja tykkää suosikkikohteistasi.',
			// en: ''
		}
	},
	'kahvilat': {
		desc: {
			fi: 'Kaikki Joensuun kahvilat yhdessä paikassa. Tutustu Joensuun suosituimpiin kahviloihin, löydä omat suosikkisi, ja äänestä tykkäämällä.',
			// en: ''
		}
	},
	'baarit-ja-yoelama': {
		desc: {
			fi: 'Baarit ja yökerhot Joensuussa. Tutustu Joensuun yöelämään ja löydä omat suosikkisi baarien, yökerhojen, terassien ja ravintolalaivojen joukosta.',
			// en: ''
		}
	},
	'ulkoilu-ja-liikunta': {
		desc: {
			fi: 'Ulkoilu ja liikunta Joensuussa. Tutustu Joensuun suosituimpiin ulkoilu- ja liikuntamahdollisuuksiin. Löydä omat suosikkisi mm. luontoreittien, uimarantojen ja eri liikuntalajien joukosta.',
			// en: ''
		}
	},
	'museot': {
		desc: {
			fi: 'Museot ja näyttelyt Joensuussa. Tutustu Joensuun suosituimpiin museoihin ja näyttelyihin, kuten Carelicumiin, Bunkkerimuseoon ja Taidemuseo Onniin.',
			// en: ''
		}
	},
	'lapsiperheille': {
		desc: {
			fi: 'Tekemistä lapsiperheille Joensuussa. Löydä hauskaa tekemistä lapsille ja lapsenmielisille tutustumalla kaupungin suosituimpiin elämyksiin.',
			// en: ''
		}
	},
	'tapahtumat': {
		desc: {
			fi: 'Joensuun Vuotuiset tapahtumat. Tutustu Joensuun suurimpiin vuosittain järjestettäviin tapahtumiin, kuten Ilosaarirockiin, Joen yöhön ja Suven avaukseen.',
			// en: ''
		}
	},
	'uutuudet': {
		desc: {
			fi: 'Uutuudet Joensuussa. Löydä ensimmäisten joukossa Joensuun uusimmat kohteet, kuten uudet ravintolat, kahvilat, baarit ja liikuntapaikat.',
			// en: ''
		}
	},
	'kaikkiRuokailu': {
		desc: {
			fi: 'Ravintolat ja lounaspaikat Joensuussa. Löydä Joensuun parhaat ravintolat ja tykkää suosikkikohteistasi.',
			// en: ''
		}
	},
	'kansainvaliset': {
		desc: {
			fi: 'Kansainväliset ravintolat Joensuussa. Löydä suosikkisi mm. kiinalaisten, intialaisten, nepalilaisten ja kreikkalaisten ravintoloiden joukosta.',
			// en: ''
		}
	},
	'lounas': {
		desc: {
			fi: 'Kaikki Joensuun lounaspaikat yhdessä paikassa. Tutustu Joensuun suosituimpiin lounaspaikkoihin, löydä omat suosikkisi, ja äänestä tykkäämällä.',
			// en: ''
		}
	},
	'pikaruoka': {
		desc: {
			fi: 'Pikaruokaravintolat Joensuussa. Tutustu kaikkiin Joensuun pikaruokapaikkoihin ja löydä omat suosikkisi nopeaan nälkään.',
			// en: ''
		}
	},
	'pizzeriatJaGrillit': {
		desc: {
			fi: 'Pizzeriat ja grillit Joensuussa. Tutustu Joensuun suosituimpiin ravintoloihin ja löydä nopeaa purtavaa, tekipä mielesi sitten pizzaa, kebabia, hampurilaista, wingsejä tai muuta grilliruokaa.',
			// en: ''
		}
	},
	'pubiruoka': {
		desc: {
			fi: 'Pubiruoka Joensuussa. Tutustu kaikkiin Joensuun pubeihin ja baareihin, jotka tarjoavat annoksia ja pientä naposteltavaa juomien ohella.',
			// en: ''
		}
	},
	'ravintolat': {
		desc: {
			fi: 'Kaikki Joensuun ravintolat yhdessä paikassa. Tutustu Joensuun suosituimpiin ravintoloihin, löydä omat suosikkisi ja äänestä tykkäämällä.',
			// en: ''
		}
	},
	'frisbeegolf': {
		desc: {
			fi: 'Frisbeegolfradat Joensuussa. Tutustu kaikkiin Joensuun alueen frisbeegolfratoihin ja löydä niistä suosikkisi.',
			// en: ''
		}
	},
	'jalkapallo': {
		desc: {
			fi: 'Jalkapallokentät Joensuussa. Tutustu kaikkiin Joensuun alueen jalkapallokenttiin ja löydä niistä suosikkisi.',
			// en: ''
		}
	},
	'luistelu': {
		desc: {
			fi: 'Luistelu- ja jääkiekkokentät Joensuussa. Tutustu kaikkiin Joensuun alueen luistinratoihin ja löydä niistä suosikkisi.',
			// en: ''
		}
	},
	'luontoreitit': {
		desc: {
			fi: 'Luontoreitit Joensuussa. Tutustu kaikkiin Joensuun alueen luontoreitteihin ja ulkoilumahdollisuuksiin, kuten Koliin, Utran saariin ja Kuhasaloon.',
			// en: ''
		}
	},
	'rantalentopallo': {
		desc: {
			fi: 'Rantalentopallo Joensuussa. Tutustu kaikkiin Joensuun alueen Beach volley -kenttiin ja löydä niistä suosikkisi.',
			// en: ''
		}
	},
	'tennis': {
		desc: {
			fi: 'Tennis Joensuussa. Tutustu kaikkiin Joensuun alueen tenniskenttiin ja löydä niistä suosikkisi.',
			// en: ''
		}
	},
	'uiminen': {
		desc: {
			fi: 'Uimarannat ja uiminen Joensuussa. Tutustu Joensuun alueen suosituimpiin uimarantoihin sekä uimahalleihin, ja löydä niistä omat suosikkisi.',
			// en: ''
		}
	},
}