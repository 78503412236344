// Global

export const featured = {
	fi: 'Suositeltu',
	// en: 'Featured'
};

export const title = {
	fi: 'Nimi',
	// en: 'Title'
};

export const closestToCentre = {
	fi: 'Lähellä keskustaa',
	// en: 'Close to centre'
};

export const closestToMe = {
	fi: 'Lähellä minua',
	// en: 'Close to me'
};

export const mostPopular = {
	fi: 'Suosituimmat',
	// en: 'Most popular'
};

export const latest = {
	fi: 'Uusimmat',
	// en: 'Latest'
};

export const pageNotFoundTitle = {
	fi: 'Sivua ei voida ladata',
	// en: 'Page cannot be loaded'
};

export const pageNotFoundMessage = {
	fi: 'Voi olla, että hakemasi sivu on poistettu tai linkissä on kirjoitusvirheitä.',
	// en: 'It could be that the page you are looking for is deleted or link is not valid.'
};

export const fromCityCentre = {
	fi: 'keskustasta',
	// en: 'from city centre'
};

export const distanceFromCityCentre = {
	fi: 'Etäisyys kaupungin keskustasta',
	// en: 'Distance from city centre'
};

export const distanceFromYourLocation = {
	fi: 'Etäisyys sijainnistasi',
	// en: 'Distance from your location'
};

export const facebookPage = {
	fi: 'Facebook-sivu',
	// en: 'Facebook page'
};

export const website = {
	fi: 'Verkkosivut',
	// en: 'Website'
};

export const sightsBannerTitle = {
	joensuu: {
		fi: 'Opaste',
		// en: 'Opaste'
	}
};

export const sightsBannerSubTitle = {
	joensuu: {
		fi: 'Koko Joensuu yhdessä paikassa',
		// en: ''
	}
};

export const reverseSortingOrder = {
	fi: 'Käännä järjestys',
	// en: 'Reverse sorting order'
};

export const sortByText = {
	fi: 'Järjestä',
	// en: 'Sort by'
};

export const findingYourLocation = {
	fi: 'Haetaan sijaintia...',
	// en: 'Finding your location...'
};

export const backToFrontPage = {
	fi: 'Takaisin etusivulle',
	// en: 'Back to front page'
};

export const noAdditionalInformation = {
	fi: 'Ei lisätietoja',
	// en: 'No additional information'
};

export const youCanAddLikesViaApplication = {
	fi: 'Voit tykätä kohteista Android-sovelluksella',
	// en: 'You can add likes by using Android application'
};

export const goBack = {
	fi: 'Takaisin',
	// en: 'Go back'
};

export const cannotLoadOfflineMessage = {
	fi: 'Tietoja ei voida ladata offline-tilassa :(',
	// en: 'We cannot load information when offline :('
};

export const seeOnMap = {
	fi: 'Katso kartalla',
	// en: 'See on map'
};

export const likes = {
	fi: 'Tykkäykset',
	// en: 'Likes'
};

export const address = {
	fi: 'Osoite',
	// en: 'Address'
};

export const close = {
	fi: 'Sulje',
	// en: 'Close'
};

export const sightCTABtnText = {
	fi: 'Lisää Joensuun elämyksiä',
	// en: ''
};

export const sightsVisible = {
	fi: ['Näytetään', 'kohdetta'],
}

export const searchSights = {
	fi: 'Hae kohteita',
}