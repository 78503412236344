import React from 'react';

const BucketListInfo = () => (
	<div className="bucket-list__info">
		<div className="bucket-list__info-inner">
			<p className="bucket-list__infotext">
				Bucket List Joensuu on lista asioista, jotka jokaisen Joensuussa asuvan tai vierailevan tulisi tehdä ainakin kerran. Lista on käyttäjien ylläpitämä, ja voit vaikuttaa listaan tykkäämällä nykyisistä asioista tai lisäämällä uusia <a href="https://play.google.com/store/apps/details?id=opaste.opaste" target="_blank" rel="noopener noreferrer">Android-sovelluksen</a> kautta.
			</p>
		</div>
	</div>
);

export default BucketListInfo;