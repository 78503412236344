import React from 'react';
import PropTypes from 'prop-types';
import adBlocker from 'just-detect-adblock';

const clientId = 'ca-pub-7682658468576858';

const slots = {
	frontpage: '6517097926',
	sightsList: '4845770090',
	sightPage: '2577852914',
	bucketList: '6916359657',
};


class GoogleAd extends React.Component {
	constructor(props) {
		super(props);
	};

	shouldComponentUpdate() {
		return false;
	}

	componentDidMount() {
		window.adsbygoogle = window.adsbygoogle || [];
    	window.adsbygoogle.push({ });
	};

	render() {
		if (adBlocker.isDetected()) return null;

		const slot = slots[this.props.slot];
		if (!slot) {
			console.error(`no slot ${this.props.slot} found`);
			return null;
		}

		return (
			<ins
				className="adsbygoogle"
				data-ad-client={clientId}
				data-ad-slot={slot}
				data-ad-format="auto"
				data-full-width-responsive="true"
				style={{
					...this.props.style,
					display: 'block',
				}}
			/>
		);
	};
}

GoogleAd.defaultProps = {
	style: { },
};

GoogleAd.propTypes = {
	style: PropTypes.object,
};

export default GoogleAd;
