import React from 'react';
import { connect } from 'react-redux';
import CategoryListItem from '../global/CategoryListItem';
import PageReady from '../global/PageReady';
import pages from '../../data/pages';

const SightsCategoryList = ({ lang, categories, title, showAd }) => (
	<div className="container">
		{title && (
			<div className="category-list__title-wrapper">
				<p className="category-list__title">{title}</p>
			</div>
		)}

		<div className="category-list">
			{categories.map(category => (
				<CategoryListItem
					key={category.pageName}
					root={pages.sights.link[lang]}
					{...category}
				/>
			))}
		</div>

		<PageReady />
	</div>
);

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(SightsCategoryList);