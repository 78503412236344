import React from 'react';
import { connect } from 'react-redux';
import { closestToCentre, closestToMe, title, mostPopular, reverseSortingOrder, sortByText } from '../../data/translations';
import { sortByTitle, sortByDistance, sortByUserDistance, sortByBest, flipSortBy, resetReverseSort } from '../../actions/sightFilters';
import { setCoordinates } from '../../actions/user';
import { getDistanceBetweenPoints } from '../../utils/helpers';
import { updateSights } from '../../actions/sights';

const sortByTranslations = {
	title,
	distance: closestToCentre,
	userDistance: closestToMe,
	best: mostPopular
};

class SightListFilters extends React.Component {
	addUserDistanceToSights = (userCoords) => {
		const sights = this.props.sights.map(sight => ({
			...sight,
			distanceFromUser: getDistanceBetweenPoints(sight.coordinates, userCoords)
		}));
		this.props.dispatch(updateSights(sights));
	};

	onPositionSuccess = (pos) => {
		this.addUserDistanceToSights(pos.coords);
		this.props.dispatch(setCoordinates(pos.coords));
		this.props.updateList();
	};

	onPositionError = (err) => {
		alert(`Failed to get location: ${err.message}`);
		this.props.dispatch(sortByBest());
	};

	changeSortBy = (e) => {
		const actions = {
			title: sortByTitle,
			distance: sortByDistance,
			userDistance: sortByUserDistance,
			best: sortByBest
		};
		const action = e.target.value;
		if (action === 'userDistance' && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.onPositionSuccess, this.onPositionError, { enableHighAccuracy: true });
		}
		this.props.dispatch(actions[action]());
		this.props.dispatch(resetReverseSort());
	};

	flipSortBy = () => {
		this.props.dispatch(flipSortBy());
	};

	render() {
		const { categoryName, sortBy, reversedSort, lang } = this.props;
		return (
			<div className="list-filters">
				<h1 className="list-filters__category-name">{categoryName}</h1>
				<div className="list-filters__modifiers">
					<div className="list-filters__select-wrapper">
						<select
							className="list-filters__select"
							value={sortBy}
							onChange={this.changeSortBy}
							aria-label={sortByText[lang]}
						>
							<option value="best">{mostPopular[lang]}</option>
							{navigator.geolocation && <option value="userDistance">{closestToMe[lang]}</option>}
							<option value="distance">{closestToCentre[lang]}</option>
							<option value="title">{title[lang]}</option>
						</select>
						<div className="list-filters__select-value">
							<div>{sortByTranslations[sortBy][lang]}</div>
							<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M24 3l-12 18-12-18z"/></svg>
						</div>
					</div>
					<button
						className="btn-unstyled list-filters__sort-order"
						onClick={this.flipSortBy}
						aria-label={reverseSortingOrder[lang]}
					>
						{ reversedSort ? (
							<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path d="M6 3l-6 8h4v10h4v-10h4l-6-8zm16 6h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>
						) : (
							<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path d="M6 21l6-8h-4v-10h-4v10h-4l6 8zm16-12h-8v-2h8v2zm2-6h-10v2h10v-2zm-4 8h-6v2h6v-2zm-2 4h-4v2h4v-2zm-2 4h-2v2h2v-2z"/></svg>
						)}
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sortBy: state.sightFilters.sortBy,
	lang: state.app.currentLanguage,
	sights: state.sights
});

export default connect(mapStateToProps)(SightListFilters);