import React from 'react';
import { Link } from 'react-router-dom';

const imagePath = '/images/categories/';

const CategoryListItem = ({ root, image, title, pageName }) => (
	<div className="category-item">
		<Link
			to={`${root}/${pageName}`}
			className="category-item__content"
			style={image ? { backgroundImage: `url('${imagePath + image}')`} : {}}
		>
			<h2 className="category-item__title">{title}</h2>
		</Link>
	</div>
);

export default CategoryListItem;