export default (state = {}, action) => {
	switch (action.type) {
		case 'SET_DETAILS':
			return {
				...state,
				[action.id]: {
					...state[action.id],
					details: action.details,
					initialDetails: false
				}
			}
		default:
			return state;
	}
};