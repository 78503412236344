export default (state = {}, action) => {
	switch (action.type) {
		case 'SET_COORDINATES':
			return {
				...state,
				coords: action.coords
			};
		default:
			return state;
	}
};