import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import SightsCategoryList from './SightsCategoryList';
import SightsList from './SightsList';
import Banner from '../global/Banner';
import { sightsBannerTitle, sightsBannerSubTitle } from '../../data/translations';
import { sightsMeta, sightsListMeta, allSightsMeta } from '../../data/meta';
import { cfirst } from '../../utils/helpers';
import Loader from '../global/Loader';
import NotFoundPage from '../global/NotFoundPage';

class SightsCategoryRedirect extends React.Component {
	constructor(props) {
		super(props);
		this.state = { notFoundDelayOver: false };
	};

	findCurrentCategory = (categories, categoryName) => {
		let currentCategory;
		const findRecursively = (subCategories) => subCategories.some(obj => {
			if (typeof obj.subCategories !== 'undefined' && obj.subCategories.length) {
				if (obj.pageName === categoryName) {
					currentCategory = obj;
					return true;
				} else {
					findRecursively(obj.subCategories)
				}
			}else {
				if (obj.pageName === categoryName) {
					currentCategory = obj;
					return true;
				}
			}
		});
		findRecursively(categories);
		return currentCategory;
	};

	orderCategories = (a, b) => {
		if (a.order < b.order)
			return -1;
		if (a.order > b.order)
			return 1;
		return 0;
	};

	setDelayed404 = () => {
		const notFoundTimeout = setTimeout(() => {
			this.setState({ notFoundDelayOver: true })
		}, 8000);
		this.notFoundTimeout = notFoundTimeout;
	};

	componentWillUnmount() {
		this.notFoundTimeout && clearTimeout(this.notFoundTimeout);
	};

	render() {
		const { match, categories, city, lang } = this.props;

		// Show top level categories if in level 1 page
		const categoryName = match.params.categoryName;
		if (!categoryName) return (
			<Fragment>
				<Helmet>
					<title>Opaste {cfirst(city)}</title>
					<meta name="description" content={sightsMeta.desc[lang]} />
				</Helmet>
				<Banner
					image="banner-joensuu.jpg"
					title={sightsBannerTitle[city][lang]}
					subTitle={sightsBannerSubTitle[city][lang]}
				/>
				{categories.length ? (
					<React.Fragment>
						<SightsCategoryList categories={categories.sort(this.orderCategories)} showAd />

						<div className="container">
							<div className="content-page">
								<h2 className="heading-underlining">Joensuu</h2>
								<p>
								Joensuu on upea kaupunki Pielisjoen varrella, jossa riittää paljon nähtävää ja koettavaa.
								Opaste Joensuu kokoaa yhteen kaikki Joensuun nähtävyydet, ravintolat, luontopolut,
								vuotuiset tapahtumat, sekä paljon muuta vapaa-ajan tekemistä kaupungissa. Tykkää suosikkikohteistasi
								äänestääksesi niitä kategorioidensa parhaiksi tai kurkkaa Bucket List osioon, joka on käyttäjien
								ehdottama top-lista asioista, jotka jokaisen Joensuussa käyvän tai asuvan tulisi ehdottomasti kokea.
								</p>
								<p>
								Opaste on tarkoitettu niin paikallisille asukkaille kuin turisteillekkin. Kaupungissa vieraileville
								se toimii kätevänä ja kattavana matkaoppaana, kun taas sivuston avulla myös jo vuosikymmeniä Joensuussa asuneet
								ovat löytäneet täysin uusia puolia rakkaasta kotikaupungistaan.
								</p>
							</div>
						</div>
					</React.Fragment>
				) : (
					<Loader />
				)}
			</Fragment>
		);

		// Show loader if category no categories loaded
		if (!categories.length) return <Loader />;

		const currentCategory = this.findCurrentCategory(categories, categoryName);
		const subCategories = currentCategory && currentCategory.subCategories;

		// Show subcategory page if subcategories are found
		if (subCategories && subCategories.length) return (
			<Fragment>
				<Helmet>
					<title>{currentCategory.title} - Opaste {cfirst(city)}</title>
					<meta name="description" content={sightsListMeta[currentCategory.pageName] ? sightsListMeta[currentCategory.pageName].desc[lang] : currentCategory.title + sightsListMeta.general.desc[lang]} />
				</Helmet>
				<SightsCategoryList
					title={currentCategory.title}
					categories={subCategories.sort(this.orderCategories)}
				/>
			</Fragment>
		);

		// Show list of sights in the selected category (if no subcategories to load)
		if (currentCategory && !currentCategory.hasSubCategories) return (
			<Fragment>
				{currentCategory.pageName === 'kaikki' ? (
					<Helmet>
						<title>{allSightsMeta.title[lang]} - Opaste {cfirst(city)}</title>
						<meta name="description" content={allSightsMeta.desc[lang]} />
					</Helmet>
				) : (
					<Helmet>
						<title>{currentCategory.title} - Opaste {cfirst(city)}</title>
						<meta name="description" content={sightsListMeta[currentCategory.pageName] ? sightsListMeta[currentCategory.pageName].desc[lang] : currentCategory.title + sightsListMeta.general.desc[lang]} />
					</Helmet>
				)}
				<SightsList category={currentCategory} />
			</Fragment>
		);

		// Show loader if current category has subcategories that haven't been loaded yet
		if (currentCategory && currentCategory.hasSubCategories) return <Loader />;

		// Show 404 page after delay to avoid flash of 404 until subcategories are loaded
		if (!this.notFoundTimeout) this.setDelayed404();
		return this.state.notFoundDelayOver ? <NotFoundPage /> : <Loader />
	}
}

const mapStateToProps = (state, props) => ({
	categories: state.sightCategories,
	match: props.match,
	lang: state.app.currentLanguage,
	city: state.app.currentLocation.city
});

export default connect(mapStateToProps)(SightsCategoryRedirect);
