import React from 'react';
import { Link } from 'react-router-dom';
import Likes from '../global/Likes';
import pages from '../../data/pages';
import { formatDistance } from '../../utils/helpers';
import { distanceFromCityCentre, distanceFromYourLocation } from '../../data/translations';

class SightsListItem extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { };
	};

	onImageError = () => {
		this.setState(() => ({ imageError: true }));
	};

	imagePath = '/images/sights/';

	render() {
		const { id, title, distanceFromCentre, distanceFromUser, image, likes, dateString, sortBy, lang } = this.props;
		return (
			<Link to={`${pages.allSights.link[lang]}/${id}`} className={`list-item ${dateString ? 'has-date' : ''}`} aria-label={title}>
				{image && !this.state.imageError ? (
					<img className="list-item__image" src={this.imagePath + image} alt={title} onError={this.onImageError} />
				) : (
					<div className="list-item__image" style={{backgroundImage: `url('${this.imagePath}no-image.png')`}}>
					</div>
				)}
				<div className="list-item__content">
					<h2 className="list-item__title">{title}</h2>
					<div className="list-item__info">
						{distanceFromUser && sortBy === 'userDistance' ? (
							<div className="list-item__distance tooltip" data-tooltip={distanceFromYourLocation[lang]}>
								<svg aria-label={distanceFromYourLocation[lang]} className="list-item__userDistance-icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11 11l1.256 5 3.744-10-10 3.75 5 1.25zm1-11c-5.522 0-10 4.395-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.42-4.478-9.815-10-9.815zm0 18c-4.419 0-8-3.582-8-8s3.581-8 8-8 8 3.582 8 8-3.581 8-8 8z"/></svg>
								<span className="list-item__userDistance-text">{formatDistance(distanceFromUser)}</span>
							</div>
						) : (
							<div className="list-item__distance tooltip" data-tooltip={distanceFromCityCentre[lang]}>
								<svg aria-label={distanceFromCityCentre[lang]} className="list-item__distance-icon" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M6 7v-7h13v10h5v14h-23v-17h5zm0 16v-4h-1v4h1zm8-4h-3v4h3v-4zm6 0h-1v4h1v-4zm2-7h-3v6h2v4h1v-10zm-5-10h-9v20h1v-5h7v5h1v-20zm-13 20v-4h2v-9h-3v13h1zm17-6h-1v-2h1v2zm-17-2h1v2h-1v-2zm8 1h-2v-2h2v2zm3 0h-2v-2h2v2zm-10-4v2h-1v-2h1zm7 1h-2v-2h2v2zm3 0h-2v-2h2v2zm-3-3h-2v-2h2v2zm3 0h-2v-2h2v2zm-3-3h-2v-2h2v2zm3 0h-2v-2h2v2z"/></svg>
								<span className="list-item__distance-text">{distanceFromCentre ? formatDistance(distanceFromCentre) : '--m'}</span>
							</div>
						)}
						<Likes likes={likes} lang={lang} />
					</div>
				</div>
				{dateString && (
					<div className="list-item__date">
						<span className="list-item__date-text">{dateString}</span>
						<svg aria-hidden="true" className="list-item__date-icon" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"/></svg>
					</div>
				)}
			</Link>
		);
	};
};

export default SightsListItem;