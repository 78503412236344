export default {
	sights: {
		link: {
			fi: '/elamykset',
			// en: '/sights'
		},
		title: {
			fi: 'Elämykset',
			// en: 'Sights'
		}
	},
	allSights: {
		link: {
			fi: '/elamykset/kaikki',
			// en: '/sights/all'
		},
		title: {
			fi: 'Kaikki Elämykset',
			// en: 'All Sights'
		}
	},
	bucketList: {
		link: {
			fi: '/bucket-list',
			// en: '/bucket-list-en'
		},
		title: {
			fi: 'Bucket List',
			// en: 'Bucket List'
		}
	},
	privacyPolicy: {
		link: {
			fi: '/tietosuoja',
			// en: '/privacy'
		},
		title: {
			fi: 'Tietosuojalauseke',
			// en: 'Privacy policy'
		}
	},
	cookiePolicy: {
		link: {
			fi: '/evasteet',
			// en: '/cookies'
		},
		title: {
			fi: 'Evästekäytäntömme',
			// en: 'Cookie policy'
		}
	},
	about: {
		link: {
			fi: '/tietoa',
			// en: '/cookies'
		},
		title: {
			fi: 'Tietoa Opasteesta',
			// en: 'Cookie policy'
		}
	},
	map: {
		link: {
			fi: '/kartta',
			// en: '/cookies'
		},
		title: {
			fi: 'Kartta',
			// en: 'Cookie policy'
		}
	}
}