import React from 'react';
import { connect } from 'react-redux';
import { latest, sortByText, mostPopular } from '../../data/translations';
import { sortByBest, sortByLatest } from '../../actions/bucketListFilters';

const sortByTranslations = {
	best: mostPopular,
	latest
};

class BucketListFilters extends React.Component {
	changeSortBy = (e) => {
		const actions = {
			best: sortByBest,
			latest: sortByLatest
		};
		const action = e.target.value;
		this.props.dispatch(actions[action]());
	};

	render() {
		const { sortBy, lang } = this.props;
		return (
			<div className="list-filters">
				<h1 className="list-filters__category-name bucket-list__heading">
					<span>Bucket List</span>
				</h1>
				<div className="list-filters__modifiers">
					<div className="list-filters__select-wrapper" style={{ marginRight: 0 }}>
						<select
							className="list-filters__select"
							value={sortBy}
							onChange={this.changeSortBy}
							aria-label={sortByText[lang]}
						>
							<option value="best">{mostPopular[lang]}</option>
							<option value="latest">{latest[lang]}</option>
						</select>
						<div className="list-filters__select-value">
							<div>{sortByTranslations[sortBy][lang]}</div>
							<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path d="M24 3l-12 18-12-18z"/></svg>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sortBy: state.bucketListFilters.sortBy,
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(BucketListFilters);