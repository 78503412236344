import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PageReady from '../global/PageReady';
import { aboutMeta } from '../../data/meta';


const About = ({ lang }) => (
	<Fragment>
		<Helmet>
			<title>{aboutMeta.title[lang]} - Opaste Joensuu</title>
			<meta name="description" content={aboutMeta.desc[lang]} />
		</Helmet>
		<div className="container">
			<div className="content-page">
				<h1 className="heading-underlining">Tietoa Opasteesta</h1>

				<h2>Kohteet</h2>
				<p>
					Opasteen kohteiden tietoja ylläpidetään käsin. Mikäli löydät virheellistä/vanhaa tietoa tai haluat ehdottaa uutta kohdetta Opasteeseen, voit lähettää meille sähköpostia osoitteeseen <a href="mailto:opaste.app@gmail.com">opaste.app@gmail.com</a>.
				</p>

				<h2>Kuvat</h2>
				<p>
					Opasteessa käytetyt kuvat ovat sekä Opasteen tekijöiden ottamia että käyttäjien lähettämiä. Kaikkien kuvien tekijänoikeudet säilyvät kuvien ottajalla. Jos haluat lähettää meille kuvia kohteeseen tai käyttää Opasteesta löytyviä kuvia muualla, voit olla meihin yhteydessä sähköpostitse <a href="mailto:opaste.app@gmail.com">opaste.app@gmail.com</a>.
				</p>
				<p>
				Erityiskiitos useista kuvista David Huovelinille, Instagram: <a href="https://www.instagram.com/dd_avid/?hl=fi" target="_blank" rel="noopener noreferrer">@dd_avid</a>.
				</p>
			</div>
		</div>
		<PageReady />
	</Fragment>
);

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(About);