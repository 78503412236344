const bucketListFiltersReducerDefaultState = {
	sortBy: 'best'
};

export default (state = bucketListFiltersReducerDefaultState, action) => {
	switch (action.type) {
		case 'BL_SORT_BY_BEST':
			return {
				...state,
				sortBy: 'best'
			};
		case 'BL_SORT_BY_LATEST':
			return {
				...state,
				sortBy: 'latest'
			};
		default:
			return state;
	}
};