import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PageReady from '../global/PageReady';
import { cookiePolicyMeta } from '../../data/meta';


const CookiePolicy = ({ lang }) => (
	<Fragment>
		<Helmet>
			<title>{cookiePolicyMeta.title[lang]} - Opaste Joensuu</title>
			<meta name="description" content={cookiePolicyMeta.desc[lang]} />
		</Helmet>
		<div className="container">
			<div className="content-page">
				<h1 className="heading-underlining">Eväste&shy;käytäntömme</h1>
				<h2>Mikä eväste?</h2>
				<p>
					Eväste on yksinkertainen tekstitiedosto, jonka sivuston palvelin tallentaa tietokoneeseesi tai mobiililaitteeseesi.
					Vain kyseinen palvelin pystyy noutamaan tai lukemaan tämän evästeen sisältöjä eli jokainen eväste on selaimellesi yksilöllinen.
					Eväste sisältää joitakin nimettömiä tietoja, kuten yksilöllisen tunnisteen, sivuston nimen ja joitakin numeroita. Sen avulla
					sivusto voi muistaa tiettyjä asioita, kuten suosikkiasetuksesi. Evästeet saattavat olla käyttämäsi sivuston määrittämiä
					(näitä kutsutaan ensimmäisen osapuolen evästeiksi), tai ne voivat olla tarkastelemasi sivun sisältöä suorittavien sivustojen
					määrittämiä (kolmannen osapuolen evästeet).
				</p>
				<br />
				<h2>Millaisia evästeitä Opaste.app käyttää?</h2>
				<p>
					Opaste.app-sivustolla voimme käyttää evästeitä tai muita selaimeen tallennettavia tietovarastoja (esim. localStorage, sessionStorage,
					IndexedDB ja Service Worker) parantaaksemme käyttökokemusta, nopeuttaaksemme sivuston toimintaa sekä seurataksemme liikennettä
					sivustollamme.
				</p>
				<p>
					Käytännössä käytämme näitä tekniikoita tallentaaksemme selaimeesi sivuston käyttämää sisältöä (esim. Elämykset-osion
					kohteita), jotta voimme ladata ne nopeasti selaimestasi kun seuraavan kerran vierailet sivustollamme. Tämä vähentää turhaa datan
					uudelleenlataamista, ja sen myötä parantaa käyttökokemustasi nopeuttamalla sivuston avautumista, vähentämällä datansiirtoa sekä
					mahdollistamalla sivuston offline-käytön. Voimme saavuttaa nämä edut käyttämällä sekä omia että Firebasen tarjoamia toimintoja.
					Firebase on sovelluskehitysalusta, jonka omistaa Google Inc.
				</p>
				<p>
					Toiminnallisten evästeiden ja muiden selainpohjaisten tietovarastojen lisäksi käytämme Google Analytics -palvelun käyttämiä
					evästeitä. Google Analytics on yksi yleisimmistä verkkosivujen käytön analysointipalveluista, jonka avulla voimme analysoida
					sivustomme käyttöä seuraamalla mm. kävijämääriä, yleisimmin avattuja sivuja sekä sivustomme vierailuaikoja. Halutessasi voit
					kieltää Google Analyticsin käytön tältä ja muilta sivuistoilta asentamalla selaimeesi <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out -lisäosan</a>. Voit
					myös halutessasi estää evästeiden käytön kokonaan selaimesi asetuksista.
				</p>
				<br />
				<p>
					Evästekäytäntömme on päivitetty viimeksi 27.01.2019.
				</p>
			</div>
		</div>
		<PageReady />
	</Fragment>
);

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(CookiePolicy);