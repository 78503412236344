import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageReady from '../global/PageReady';
import { privacyPolicyMeta } from '../../data/meta';
import pages from '../../data/pages';


const PrivacyPolicy = ({ lang }) => (
	<Fragment>
		<Helmet>
			<title>{privacyPolicyMeta.title[lang]} - Opaste Joensuu</title>
			<meta name="description" content={privacyPolicyMeta.desc[lang]} />
		</Helmet>
		<div className="container">
			<div className="content-page">
				<h1 className="heading-underlining">Tietosuoja&shy;lauseke</h1>
				<p>
					Opaste on yksityisten henkilöiden tekemä ja ylläpitämä palvelu. Emme pidä yllä rekisteriä, mikä sisältäisi käyttäjien
					henkilötietoja, mutta keräämme tietoa sovelluksen ja verkkosivuston käytöstä Google Analyticsin ja Firebasen avulla.
					Kerättäviä tietoja voi olla esimerkiksi, mutta ei näihin rajoittuen, sovelluksen tai nettisivuston käyttöaika, avatut
					sivut tai näkymät sekä selailuun käytettävä laite. Kerättyjä henkilötietoja ovat käyttäjän laitteen tai selaimen
					ID-tunniste sekä IP-osoite. Laitteen tai selaimen ID-tunnistetta käytämme, jotta voimme erotella sivuston uudet käyttäjät
					ja sivustolle palaavat käyttäjät toisistaan. Käyttäjän IP-osoite tallennetaan anonymisoituna, jolloin sitä ei voida
					yhdistää kehenkään yksittäiseen käyttäjään. Kerättyjä tietoja käytämme sovelluksen ja sivuston käytön seurantaan ja
					niiden parantamiseen. Peruste tietojen keräämiseen ja käsittelyyn on oikeutettu kiinnostus (Legitimate interest). Tietoja
					säilytetään Google Analyticsin vakioasetusten mukaisesti maksimissaan 26 kuukautta.
				</p>
				<p>
					Yllä olevien tietojen lisäksi voimme pyytää sinulta lupaa käyttää tarkkaa sijaintiasi kun käytät “Lähellä minua” -suodatusta
					listatessa Elämyksiä. Tällöin saamme sijaintisi vasta kun olet antanut luvan selaimen tai laitteen kautta, ja tallennamme
					sijaintisi vain selaimeesi tai laitteeseesi, emmekä lähetä itse sitä muualle. Huomaa kuitenkin, että käyttämämme kolmannen
					osapuolen palvelut (Google Analytics ja Firebase) voivat käyttää tarkkaa sijaintiasi jos olet antanut luvan sen käyttöön
					verkkosivustolla tai sovelluksessa.
				</p>
				<p>
					Henkilö- ja muiden tietojen siirto on suojattu SSL-menetelmällä ja tiedot ovat tallessa Googlen palvelimilla Googlen omien
					suojausten takana. Emme luovuta tietoja säännönmukaisesti minnekään, mutta käyttämämme kolmannen osapuolen palvelun- ja
					ohjelmistontarjoajat voivat säilyttää tietoja EU:n tai Euroopan ulkopuolella. Hyödyllistä lisätietoa Googlen
					tietojenkäsittelystä ja -säilytyksestä
					 löydät <a href="https://policies.google.com/privacy?hl=fi" target="_blank" rel="noopener noreferrer">Googlen tietosuojaselosteesta</a> sekä <a href="https://support.google.com/analytics/answer/6004245?hl=fi" target="_blank" rel="noopener noreferrer">Analyticsin ohjeiden kohdasta “Datan turvaaminen"</a>.
				</p>
				<p>
					Käytämme verkkosivustollamme evästeitä. Lisätietoja evästeiden käytöstä ja niiden estämisestä
					löydät <Link to={pages.cookiePolicy.link[lang]}>evästekäytännöstämme</Link>.
				</p>
				<p>
					Tarvittaessa voit olla meihin yhteydessä sähköpostiosoitteeseen <a href="mailto:opaste.app@gmail.com">opaste.app@gmail.com</a>.
				</p>
				<br />
				<p>
					Tietosuojalauseke on päivitetty viimeksi 27.01.2019.
				</p>
			</div>
		</div>
		<PageReady />
	</Fragment>
);

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(PrivacyPolicy);