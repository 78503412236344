import { firestore } from './firestore';
import { setCategories, setSubCategories } from '../actions/sightCategories';

export const fetchSightCategories = (city, store) => {
	const categoriesRef = firestore.collection(`cities/${city}/categories`)
		.where('visible', '==', true);

	categoriesRef.onSnapshot((querySnapshot => {
		const categories = querySnapshot.docs
			.filter(doc => doc.exists)
			.map(doc => ({ ...doc.data(), firebaseId: doc.id }));

		store.dispatch(setCategories({ categories, retainSubCategories: true }));
		categories.forEach(category => fetchSubCategories(category, city, store.dispatch));
	}), err => console.warn("Error getting categories:", err));
};

const fetchSubCategories = (category, city, dispatch) => {
	if (!category.hasSubCategories) return;
	const subCategoriesRef = firestore.collection(`cities/${city}/categories/${category.firebaseId}/subCategories`)
		.where('visible', '==', true);

	subCategoriesRef.onSnapshot((querySnapshot => {
		const subCategories = querySnapshot.docs
			.filter(doc => doc.exists)
			.map(doc => doc.data());

		dispatch(setSubCategories({ pageName: category.pageName, subCategories }));
	}), err => console.warn("Error getting subcategories:", err));
};