export default (state = [], action) => {
	switch (action.type) {
		case 'SET_CATEGORIES':
			if (action.retainSubCategories) {
				const newState = action.categories;
				state.forEach((category) => {
					const pageName = category.pageName;
					if (category.subCategories) {
						const newStateCategory = newState.find(c => c.pageName === pageName);
						if (newStateCategory) newStateCategory.subCategories = category.subCategories;
					}
				});
				return newState;
			}
			return action.categories;
		case 'SET_SUBCATEGORIES':
			return [
				...state.map(category => category.pageName === action.pageName ? { ...category, subCategories: action.subCategories } : { ...category })
			];
		default:
			return state;
	}
};