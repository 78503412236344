import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import pages from '../../data/pages';

const Footer = ({ lang }) => (
	<footer className="footer">
		<div className="container">
			<div className="footer__content">
				<Link className="footer__link" to={pages.about.link[lang]}>{pages.about.title[lang]}</Link>
				<span className="footer__separator">
				</span>
				<Link className="footer__link" to={pages.privacyPolicy.link[lang]}>{pages.privacyPolicy.title[lang]}</Link>
				<span className="footer__separator">
				</span>
				<Link className="footer__link" to={pages.cookiePolicy.link[lang]}>{pages.cookiePolicy.title[lang]}</Link>
				<span className="footer__separator">
				</span>
				<a className="footer__link" href="mailto:opaste.app@gmail.com">opaste.app@gmail.com</a>
			</div>
		</div>
	</footer>
);

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(Footer);
