export const setCategories = ({ categories, retainSubCategories = false }) => ({
	type: 'SET_CATEGORIES',
	categories,
	retainSubCategories
});

export const setSubCategories = ({ pageName, subCategories }) => ({
	type: 'SET_SUBCATEGORIES',
	pageName,
	subCategories
});