export const sortByTitle = () => ({
	type: 'SORT_BY_TITLE'
});

export const sortByDistance = () => ({
	type: 'SORT_BY_DISTANCE'
});

export const sortByUserDistance = () => ({
	type: 'SORT_BY_USER_DISTANCE'
});

export const sortByBest = () => ({
	type: 'SORT_BY_BEST'
});

export const flipSortBy = () => ({
	type: 'FLIP_SORT_BY'
});

export const resetReverseSort = () => ({
	type: 'RESET_REVERSE_SORT'
});