const sortByLikes = (a, b) => {
	if (!a.date || !b.date) {
		if (a.likes === 'undefined' || b.likes === 'undefined') return 0;
		return a.likes > b.likes ? -1 : 1;
	}
	return a.likes === b.likes
		? a.date.seconds < b.date.seconds ? -1 : 1
		: a.likes > b.likes ? -1 : 1;
};

export const filterBucketListItems = (items, sortBy) => {
	let result = items.sort((a, b) => {
		if (sortBy === 'latest') {
			if (!a.date || !b.date) return 0;
			return a.date.seconds > b.date.seconds ? -1 : 1;
		} else if (sortBy === 'best') {
			return sortByLikes(a, b)
		}
	});

	return result;
};