import React from 'react';
import HeaderTopBar from './HeaderTopBar';

class Header extends React.Component {
	render() {
		return (
			<header className="header site-header">
				<HeaderTopBar />
			</header>
		);
	}
}

export default Header;