export default () => {
	const isLocalhost = window.location.href.includes('localhost:');
	if ('serviceWorker' in navigator && !isLocalhost) {
	// if ('serviceWorker' in navigator) {
		window.addEventListener('load', function () {
			navigator.serviceWorker.register('/sw.js').then(function (registration) {
				// console.log('ServiceWorker registration successful with scope: ', registration.scope);
			}, function (err) {
				// console.log('ServiceWorker registration failed: ', err);
			});
		});

		// if (navigator.serviceWorker.controller) {
		// 	navigator.serviceWorker.controller.onstatechange = function(e) {
		// 		if (e.target.state === 'redundant') {
		// 			// window.location.reload();
		// 		}
		// 	};
		// }
	}

}