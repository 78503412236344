import React from 'react';
import { isAndroid } from '../../utils/helpers';
import { likes as likesTranslation } from '../../data/translations';
import { sendGAEvent } from '../../utils/ga';

class Likes extends React.Component {
	constructor(props) {
		super(props);
		this.state = { tooltipActive: false };
	}

	componentWillUnmount() {
		this.clearTooltipTimeout();
	};

	clearTooltipTimeout = () => {
		const previousTimeout = this.state.removeClassTimeout;
		if (!previousTimeout) return;

		clearTimeout(previousTimeout);
	};

	toggleTooltipActive = () => {
		if (window.deferredPrompt && isAndroid()) {
			window.deferredPrompt.prompt();
			window.deferredPrompt = null;
		}
		this.clearTooltipTimeout();

		const removeClassTimeout = setTimeout(() => {
			this.setState(() => ({ tooltipActive: false }));
		}, this.props.tooltipTimeout);

		this.setState((prevState) => ({
			tooltipActive: !prevState.tooltipActive,
			removeClassTimeout
		}));
	};

	onClick = (e) => {
		e.stopPropagation();
		const { gaEvent, gaAction, tooltip, tooltipOnlyClickable } = this.props;
		if (gaEvent && gaAction && !this.state.tooltipActive) sendGAEvent(gaEvent, gaAction);
		if (tooltip && tooltipOnlyClickable) this.toggleTooltipActive();
	}

	render() {
		const { likes, classes, tooltip, tooltipOnlyClickable, lang } = this.props;
		return (
			<div
				className={
					'likes' + (classes ? ` ${classes}` : '') +
					(tooltip ? ' tooltip' : '') +
					(tooltipOnlyClickable ? ' tooltip--no-hover' : '') +
					(this.state.tooltipActive ? ' tooltip--active' : '')
				}
				data-tooltip={tooltip}
				onClick={this.onClick}
			>
				<svg aria-label={likesTranslation[lang]} style={{display: likes === undefined && 'none'}} className="likes__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
				<span className="likes__text">{likes}</span>
			</div>
		);
	}
}

export default Likes;