const sightFiltersReducerDefaultState = {
	sortBy: 'best',
	reversedSort: false
};

export default (state = sightFiltersReducerDefaultState, action) => {
	switch (action.type) {
		case 'SORT_BY_TITLE':
			return {
				...state,
				sortBy: 'title'
			};
		case 'SORT_BY_DISTANCE':
			return {
				...state,
				sortBy: 'distance'
			};
		case 'SORT_BY_USER_DISTANCE':
			return {
				...state,
				sortBy: 'userDistance'
			};
		case 'SORT_BY_BEST':
			return {
				...state,
				sortBy: 'best'
			};
		case 'FLIP_SORT_BY':
			return {
				...state,
				reversedSort: !state.reversedSort
			};
		case 'RESET_REVERSE_SORT':
			return {
				...state,
				reversedSort: false
			};
		default:
			return state;
	}
};