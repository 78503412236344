const appReducerDefaultState = {
	currentLanguage: 'fi',
	currentLocation: {
		city: 'joensuu',
		cityCentre: {
			lat: 62.600615,
			lng: 29.7636775
		}
	}
}

export default (state = appReducerDefaultState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};