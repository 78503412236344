import React from 'react';

const imagePath = '/images/banners/';

const Banner = ({ image, title, subTitle }) => (
	<div className="container">
		<div className="banner" style={{ backgroundImage: `url('${imagePath + image}')` }}>
			<div className="banner__content">
				<img className="banner__image" src="/images/logos/opaste.jpg" alt="Opaste Logo" />
				<div>
					<h1 className="banner__title">{title}</h1>
					<p className="banner__subtitle">{subTitle}</p>
				</div>
			</div>
		</div>
	</div>
);

export default Banner;