import ReactGA from 'react-ga';

export const sendGAEvent = (eventName, action = { }) => {
	const options = {
		sightCTAClick: {
			category: 'Sight - Link',
			action: 'CTA Click',
			label: action.title
		},
		sightAddressClick: {
			category: 'Sight - Link',
			action: 'Address Click',
			label: action.title
		},
		sightWebsiteClick: {
			category: 'Sight - Link',
			action: 'Website Click',
			label: action.title
		},
		sightLike: {
			category: 'Like',
			action: 'Sight Like',
			label: action.title
		},
		bucketListLike: {
			category: 'Like',
			action: 'Bucket List Like',
			label: action.title
		},
		bucketListItemExpand: {
			category: 'Bucket List',
			action: 'Item Expand',
			label: action.title
		},
		bucketListInfoExpand: {
			category: 'Bucket List',
			action: 'Info Expand',
		}
	};

	const eventOptions = options[eventName];
	if (!eventOptions) return;

	ReactGA.event(options[eventName]);
};