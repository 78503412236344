import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pageNotFoundTitle, pageNotFoundMessage, backToFrontPage } from '../../data/translations';

const NotFoundPage = ({ title, message, lang }) => (
	<div className="container" style={{paddingTop: '5rem'}}>
		<h1>{title[lang]} :(</h1>
		<p>{message[lang]}</p>
		<Link to="/">{backToFrontPage[lang]}</Link>
	</div>
);

NotFoundPage.defaultProps = {
	title: pageNotFoundTitle,
	message: pageNotFoundMessage
};

const mapStateToProps = (state) => ({
	lang: state.app.currentLanguage
});

export default connect(mapStateToProps)(NotFoundPage);