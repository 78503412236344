import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter from './routers/AppRouter';
import initAppStore from './utils/initAppStore';
import 'normalize.css/normalize.css';
import './styles/styles.scss';
import browserCheck from './utils/browserCheck';
import registerServiceWorker from './utils/register-sw';
import initAppInstallPrompt from './utils/appInstallPrompt';

const rootDiv = document.getElementById('app');

// Not server side rendered
if (rootDiv.childNodes.length === 0) {
	browserCheck();
	const store = initAppStore(store);

	// store.subscribe(() => {
	// 	console.log(store.getState());
	// });

	const app = (
		<Provider store={store}>
			<AppRouter />
		</Provider>
	);

	document.getElementById('site-loader').style.display = 'none';

	ReactDOM.render(app, rootDiv);
	registerServiceWorker();
	initAppInstallPrompt();
}

